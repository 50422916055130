<template>
  <button @click="resetQuiz" class="btn reset-btn">Recommencer le quiz</button>
</template>

<script>
import { mixins } from '../mixins/mixins.js'
export default {

  name: 'resetBtn',
  mixins: [mixins]
}
</script>

<style lang="scss">
  .reset-btn {
    margin-top: 100px;
    font-size: 20px;
  }
</style>

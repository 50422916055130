<template>
  <div class="jobsList" data-simplebar>
      <ul>
        <li v-for="(result, index) in results" :key="`result-${index}`"
        @click="onclick(index)"
        v-on:mouseover="mouseover(index)"
        v-on:mouseleave="mouseleave"
        :class="['job', (activeLink===index ? 'active' : ''), ( prevIndex===index ? 'clicked' : '') ]"
        :style="{color:percentageColor(result.percentage)}"
        >
          <div class="count">{{ index+1 }}. </div>
          <span>{{ jobs[result.jobIndex].metier }}</span>
          <span class="percentage">{{ result.percentage }}%</span>
        </li>
      </ul>
  </div>
</template>

<style lang="scss">

  .jobsList {
    margin-top: 25px;
    margin-bottom: 50px;
    width: 100%;
    margin-left: auto;
    height: 200px;
    // overflow-y: scroll;

    @media all and (max-width: 540px) {
      text-align: left;
    }
    ol,
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      padding-right: 30px;

      li.job {
        cursor: pointer;
        font-size: 16px;
        margin-left: 0px;
        line-height: 1.2;
        margin-bottom: 8px;
        position: relative;

        .percentage {
          &:before {
            content: ' - ';
          }
        }

        @media all and (max-width: 540px) {
           font-size: 15px;
           display: flex;

           .percentage {
             margin-left: auto;
             &:before {
               content: '';
             }
           }
        }

        .count{
          position:relative;
          display: inline-block;
          padding-right: 5px;
        }
      }

      .clicked {
        text-decoration: underline;
        @media all and (max-width: 540px) {
           text-decoration: inherit;
        }
      }
    }
  }
  .active {
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media all and (max-width: 540px) {
      display: inherit;
    }

    @media all and (max-width: 540px) {
      align-items: left;
      justify-content: flex-start;
    }

    &::before {
      content: '';
      display: block;
      margin-left: -8px;
      margin-right: 10px;
      width: 0;
      height: 0;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-left: 8px solid #000;

      @media all and (max-width: 540px) {
        display: none;
      }
    }
  }

</style>

<script>
export default {
  name: 'resultsJobsList',

  data () {
    return {
      // results: [],
      activeLink: 0,
      color: '#000',
      prevIndex: 0
    }
  },
  methods: {
    updateIndex: function (value) {
      this.$emit('updateIndex', value)
      this.activeLink = value
    },
    onclick (value) {
      this.updateIndex(value)
      this.prevIndex = value
    },
    mouseover (index) {
      this.updateIndex(index)
    },
    mouseleave () {
      this.updateIndex(this.prevIndex)
    },
    percentageColor ($percentage) {
      let color = 'black'
      if ($percentage >= 90) {
        color = 'green'
      } else if ($percentage >= 80 && $percentage < 90) {
        color = 'YellowGreen'
      } else if ($percentage >= 70 && $percentage < 80) {
        color = 'orange'
      } else if ($percentage >= 0 && $percentage < 70) {
        color = 'Tomato'
      }
      return color
    }
  },
  computed: {
    jobs () {
      return this.$store.state.jobs
    },
    userResponses () {
      return this.$store.state.userResponses
    },
    results () {
      return this.$store.state.results
    }
  }/* ,
  mounted: function () {
    this.theResults()
  } */
}
</script>

<template>

  <main class="results page" v-if="dataLoaded">

    <section class="results-content mainContent" v-if="results.length != 0">
      <sectionHeader/>
      <h1>Résultats</h1>
      <resultsJobsList @updateIndex="updateIndex" />

        <mobileVideo v-if="results.length != 0 && jobs[results[index].jobIndex].video"
          :videoSrc="jobs[results[index].jobIndex].video"
          :poster="jobs[results[index].jobIndex].img"
          :key="index"
        />

       <div v-else class="mobile-img">
        <img v-if="results.length != 0 && jobs[results[index].jobIndex].img " :src="jobs[results[index].jobIndex].img" :key="index"/>
        <img v-else :src="details.intro_image"/>
      </div>

      <article class="job-description">
        <header class="job-section-header">
          <div class="job-header">
            <h2 class="job-title">{{jobs[results[index].jobIndex].metier}}</h2>
            <span class="reset" @click="resetQuiz">Redémarrer le quizz</span>
          </div>

          <nav class="jobs-description-nav">
            <div class="btn btn-description" :class="{active:!showCursus }" v-on:click="fnShowDescription" >Description <vue-feather type="arrow-right" size="30"></vue-feather></div>
            <div class="btn btn-cursus" :class="{active:showCursus}"  v-on:click="fnShowCursus">Cursus <vue-feather type="arrow-right" size="30"></vue-feather></div>
          </nav>
        </header>

        <div class="scroll-text-wrapper" data-simplebar v-if="showCursus===false">
          <div class="content description-content" v-html="jobs[results[index].jobIndex].description"></div>
        </div>

        <div class="scroll-text-wrapper" data-simplebar v-else>
          <div class="content cursus-content" v-html="jobs[results[index].jobIndex].cursus">
        </div>
        </div>
      </article>

    </section >

    <section  class="no-results-content mainContent" v-else>
      <sectionHeader/>
      <h1>Résultats</h1>
        <article class="job-description">
          Aucun résultats ne semble correspondre...
        </article>
      <resetBtn/>
    </section>

    <sideVideo v-if="results.length != 0 && jobs[results[index].jobIndex].video" :videoSrc="jobs[results[index].jobIndex].video" :key="index"/>
    <sideImage v-else-if="results.length != 0 && jobs[results[index].jobIndex].img" :imgSrc="jobs[results[index].jobIndex].img" :key="index"/>
    <sideImage v-else :imgSrc="details.intro_image"/>
  </main>
</template>

<script>
import sectionHeader from '@/components/sectionHeader.vue'
import sideVideo from '@/components/sideVideo.vue'
import mobileVideo from '@/components/mobileVideo.vue'
import sideImage from '@/components/sideImage.vue'
import resultsJobsList from '@/components/results/resultsJobsList.vue'
import resetBtn from '@/components/reset.vue'
import axios from 'axios'
import { mixins } from '../mixins/mixins.js'

export default {
  name: 'Results',
  mixins: [mixins],
  components: {
    sectionHeader,
    sideVideo,
    mobileVideo,
    resultsJobsList,
    resetBtn,
    sideImage
  },
  data () {
    return {
      index: 0,
      contentTitle: 'Description du métier',
      btnLabel: 'Cursus',
      showCursus: false,
      parameters: [],
      dataLoaded: false,
      usersResults: null
    }
  },

  computed: {
    jobs () {
      return this.$store.state.jobs
    },
    quizResults () {
      return this.$store.state.userResponses
    },
    details () {
      return this.$store.state.details
    },
    results () {
      return this.$store.state.results
    }
  },

  methods: {
    updateIndex (variable) {
      this.index = variable
    },
    toggleCursus () {
      if (this.showCursus === false) {
        this.showCursus = true
        this.contentTitle = 'Cursus'
        this.btnLabel = 'Description'
      } else {
        this.contentTitle = 'Description du métier'
        this.showCursus = false
        this.btnLabel = 'Cursus'
      }
      console.log(this.showCursus)
    },
    fnShowCursus () {
      this.showCursus = true
      this.contentTitle = 'Cursus'
    },
    fnShowDescription () {
      this.showCursus = false
      this.contentTitle = 'Description du métier'
    }
  },

  mounted () {
    // récupérer le paramaètre ID
    const urlParams = new URLSearchParams(window.location.search)
    this.parameters = ''
    this.parameters = urlParams.get('entry_id')
    if (!this.parameters) {
      this.dataLoaded = true
    } else {
      axios.get(
        this.$store.state.sourceUrl + '/wp-json/wp/v2/entree/' + this.parameters,
        {
          // withCredentials: true,
          auth: {
            username: 'jerome',
            password: 'gG1t 6T8m tmL9 UzBS fAEO gpgx'
          }
        })
        .then(response => {
          this.usersResults = response.data
          console.log('resultats obtenus')
          console.log(this.usersResults)
          this.$store.state.results = this.usersResults.acf.resultat
          this.dataLoaded = true
        })
        .catch(error => {
          console.log('Bad news with the datas')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">

  .results {
    .results-content .section-header {
      margin-bottom: 25px;
    }

    .no-results-content .section-header {
      margin-bottom: 32vh;
    }
  }

  .results-content  {
     @media all and (max-width: 768px) {
      h1 {
        font-size: 50px;
      }

      @media all and (max-width: 540px) {
        h1 {
          text-align: left;
          width: 100%;
        }
      }
    }
  }

  .mobile-img {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .job-description {
    width: 100%;
    max-width: 1200px;
    font-size: 20px;

    .job-section-header {
      margin-bottom: 30px;
      align-items: center;

      .job-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;

        .job-title {
          width: 70%;
          margin-right: auto;
          text-align: left;
          min-height: 80px;

          @media all and (max-width: 768px) {
            font-size: 28px;
          }

          @media all and (max-width: 540px) {
            font-size: 20px;
          }
        }

        .reset {
          font-size: 16px;
          text-decoration: underline;
          cursor: pointer;

          @media all and (max-width: 540px) {
            font-size: 12px;
          }
        }
      }

      .jobs-description-nav {
        display: flex;
        // justify-content: space-between;

        .btn-description,
        .btn-cursus {
          vertical-align: middle;
          font-size: 18px;
          border-width: 1px;
          transition: all 0.3s;

          @media all and (max-width: 768px) {
            font-size: 16px;
          }

          &:first-of-type {
            margin-right: 10px;
          }

          &.active {
            background-color: #000;
            color: #fff;
          }

          .vue-feather--arrow-right {
            display: inline-block;
            width: 0;
            overflow: hidden;
            //font-size: 30px;
            vertical-align: middle;
            transition: margin 0.3s;
          }

          &.active,
          &:hover {
            .vue-feather--arrow-right {
              width: 20px;
              margin-left: 8px;
            }
          }
        }
      }
    }

    .scroll-text-wrapper {
      height: auto;
      @media all and (min-width: 769px) {
        height: 390px;
      }
    }

    .content {
      text-align: left;
      @media all and (max-width: 768px) {
        font-size: 18px;
        padding-right: 0;

        ul, li {
          padding-right: 0;

        }
      }
      @media all and (max-width: 540px) {
        font-size: 16px;
      }

      table {
        width: 100%;
        border-spacing: 0;

        @media all and (max-width: 768px) {
          font-size: 18px !important;
        }
        @media all and (max-width: 540px) {
          font-size: 16px !important;
        }
      }

      ul {
        padding-left: 18px;
      }
    }
  }
</style>

<template>
  <section v-if="videoSrc" class="sideVideo" >
    <button :class="paused === true ? 'paused':'played' " class="play-btn" @click="toggleVideoPause"></button>
    <video class="jobvideo" ref="videoRef" id="video" @click="toggleVideoPause">
      <source :src="videoSrc" type="video/mp4">
    </video>
    <div class="fullscreenbtn" @click="toggleFullscreen">
      <svg viewBox="0 0 24 24">
        <g>
            <path fill="#fff" d="M0 0h24v24H0z"/>
            <path fill="#000" d="M16 3h6v6h-2V5h-4V3zM2 3h6v2H4v4H2V3zm18 16v-4h2v6h-6v-2h4zM4 19h4v2H2v-6h2v4z"/>
        </g>
    </svg>
    </div>
  </section>
</template>

<script>
export default {
  name: 'sideVideo',
  props: {
    videoSrc: String
  },

  data () {
    return {
      paused: true
    }
  },

  methods: {
    playVideo () {
      this.$refs.videoRef.play()
      this.paused = false
      console.log('hello')

      // https://stackoverflow.com/questions/49163182/how-to-bind-the-paused-attribute-of-video-element-in-vue-js-to-a-function-which?rq=1
    },

    toggleVideoPause () {
      if (this.paused === false) {
        this.$refs.videoRef.pause()
        this.paused = true
      } else {
        this.$refs.videoRef.play()
        this.paused = false
      }
    },

    toggleFullscreen () {
      console.log('video somthing')
      if (this.$refs.videoRef.requestFullScreen) {
        this.$refs.videoRef.requestFullScreen()
      } else if (this.$refs.videoRef.webkitRequestFullScreen) {
        this.$refs.videoRef.webkitRequestFullScreen()
      } else if (this.$refs.videoRef.mozRequestFullScreen) {
        this.$refs.videoRef.mozRequestFullScreen()
      }
    }
  }
}

</script>

<style lang="scss">
  .sideVideo {
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom:0;
    left: 50%;
    right: 0;

    @media all and (max-width: 768px) {
      display: none;
    }

    .play-btn {
      width: 150px;
      height: 150px;
      border: 2px solid #fff;
      border-radius: 50%;
      position: absolute;
      z-index: 300;
      top: 50%;
      left: 50%;
      background-color: transparent;
      transform: translate(-50%, -50%);
      cursor: pointer;
      transition: 0.3s opacity;
      opacity: 1;

      &.played {
        opacity: 0;
      }

      &:hover {
        opacity: 0.5;
      }

      &::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        top: 50%;
        left: 58%;
        position: absolute;
        transform: translate(-50%, -50%);
        border-top: 50px solid transparent;
        border-bottom: 50px solid transparent;
        border-left: 80px solid #fff;
      }
    }

    .jobvideo:not(:-webkit-full-screen) {
      min-height: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

     video:-webkit-full-screen {
        width: 100%;
        height: 100%;
        max-height: 100%;
      }

    .fullscreenbtn {
      position: absolute;
      bottom: 30px;
      right: 30px;
      z-index: 10;
      color: #fff;
      cursor: pointer;

      svg {
        width: 30px;
        height: 30px;
      }
    }
  }
</style>

<template>

    <video class="mobile-video" ref="videoRef" :poster="poster" controls>
      <source :src="videoSrc" type="video/mp4">
    </video>

</template>

<script>
export default {
  name: 'mobileVideo',
  props: {
    videoSrc: String,
    poster: String
  }
}
</script>

<style lang="scss">

.mobile-video {
    width: 100%;
    height: auto;
    margin-top: 0;
    margin-bottom: 30px;
    object-fit: cover;

    @media all and (min-width: 769px) {
      display: none;
    }
  }

</style>
